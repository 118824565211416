/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Box, Container, Typography } from "@mui/material";
import { StaticImage } from "gatsby-plugin-image"


const Hero = () => {
    return (
        <Box sx={{ position: 'relative', minHeight: '500px', overflow: 'hidden' }}>
            <Box sx={{ position: 'absolute', left: 0, right: 0 }}>
                <StaticImage
                    src="../images/main-hero.jpg"
                    placeholder="blurred"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Eco-friendly products are more readily available than ever."
                    style={{width: '100%', minHeight: '500px', opacity: .8}}
                />
            </Box>
            <Container sx={{ position: 'relative' }} maxWidth="md">
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', pt: 15 }}>
                    <Typography variant="h2" component="h1" gutterBottom sx={{fontWeight: 900, textTransform: 'uppercase', color: '#434041', textAlign: 'center'}}>Helping you lead a more sustainable lifestyle</Typography>
                    <Typography variant="body2" sx={{textAlign: 'center'}}>We are here to help you learn about all things eco friendly, zero waste, and sustainable.</Typography>
                </Box>
            </Container>
        </Box>
    )
}

export default Hero
