/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { Box, Button, Container, Typography, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link as NavLink } from 'gatsby';
import { StaticImage } from "gatsby-plugin-image"
import CheckBoxOutlinedIcon from '@mui/icons-material/CheckBoxOutlined';


const Info = () => {
    return (
        <Container sx={{ position: 'relative', display: 'flex', flexDirection: { xs: 'column', md: 'row' }, py: 10 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', width: { xs: '100%', md: '50%' }, px: { xs: 0, md: 4 } }}>
                <Typography variant="h2" gutterBottom sx={{ color: '#7b9b4a' }}>Live Greener</Typography>
                <Typography variant="body2" sx={{ fontSize: '1.5rem' }}>Our goal at Radiant Family Home is to help educate people about the basics of living a more eco friendly lifestyle including:</Typography>
                <List>
                    <ListItem>
                        <ListItemIcon><CheckBoxOutlinedIcon color="primary" fontSize="large" /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} sx={{ fontSize: '1.5rem' }}>reducing consumption</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckBoxOutlinedIcon color="primary" fontSize="large" /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} sx={{ fontSize: '1.5rem' }}>choosing more eco-friendly options</ListItemText>
                    </ListItem>
                    <ListItem>
                        <ListItemIcon><CheckBoxOutlinedIcon color="primary" fontSize="large" /></ListItemIcon>
                        <ListItemText primaryTypographyProps={{ variant: 'body2' }} sx={{ fontSize: '1.5rem' }}>opting to do business with environmentally friendly brands</ListItemText>
                    </ListItem>
                </List>
                <Button component={NavLink} to="/about/" variant="contained" size="medium" sx={{my: 3}}>Learn more</Button>
            </Box>
            <Box sx={{ width: { xs: '100%', md: '50%' } }}>
                <StaticImage
                    src="../images/info.jpg"
                    placeholder="blurred"
                    quality={95}
                    formats={["auto", "webp", "avif"]}
                    alt="Choose eco-friendly products whenever you can."
                    style={{ width: '100%', opacity: .8 }}
                />
            </Box>
        </Container>
    )
}

export default Info
