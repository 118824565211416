import * as React from "react";

import Layout from "../components/layout"
import Seo from "../components/seo"
import Hero from "../components/hero"
import Info from "../components/info"
import FeaturedPosts from "../components/featured-posts"

const IndexPage = () => (
  <Layout>
    <Seo title="Home" />
    <Hero />
    <Info />
    <FeaturedPosts />
  </Layout>
)

export default IndexPage
